<template>
  <div
    v-if="props.sku || props.userCode || props.barcode"
    class="flex gap-2 text-xs text-grey"
    :class="[props.inline ? 'flex-row' : 'flex-col']"
  >
    <p
      v-if="props.sku && typeof props.sku === 'string'"
      class="text-left font-bold text-inherit md:ml-0"
      :style="paragraphStyles"
    >
      {{ props.sku }}
    </p>
    <div class="flex items-center gap-x-5 gap-y-3">
      <p
        v-if="props.userCode && typeof props.userCode === 'string'"
        class="flex items-center gap-1 text-inherit"
        :style="paragraphStyles"
      >
        <UiIcon
          name="user-code"
          :width="13"
          :height="12"
        />
        {{ props.userCode }}
      </p>
      <p
        v-if="props.barcode && typeof props.barcode === 'string'"
        class="flex items-center gap-1 text-inherit"
        :style="paragraphStyles"
      >
        <UiIcon
          name="barcode"
          :width="26"
          :height="13"
        />
        <span>{{ props.barcode }}</span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import UiIcon from '../../UiIcon/UiIcon.vue'

interface UiProductDetailsCodes {
  userCode?: string
  sku?: string
  barcode?: string
  inline?: boolean
}
const props = withDefaults(defineProps<UiProductDetailsCodes>(), {
  sku: '',
  userCode: '',
  barcode: '',
  inline: false,
})

const paragraphStyles = {
  fontSize: 'inherit',
  lineHeight: 'inherit',
}
</script>
